<template>
  <div>
    <v-navigation-drawer app right width="375">
      <HotLeadCarousel />
      <div class="pa-4 pt-0">
        <p class="font-weight-bold mb-4 text-typo">Action Recommendations</p>

        <v-list>
          <v-list-item v-for="n in 5" :key="n" link>
            <v-list-item-content>
              <v-list-item-title>Item {{ n }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-row class="mb-4">
      <v-col class="col-12 col-md-3">
        <ProspectTemperatureCard
          color="red"
          icon="mdi-fire-circle"
          headerText="Hot Prospects"
          valueText="13"
          btnText="View"
          trending="up"
          trendingChange="0.5%"
          rgbaColor="rgba(225, 82, 65, 0.5)"
      /></v-col>
      <v-col class="col-12 col-md-3">
        <ProspectTemperatureCard
          color="orange"
          icon="mdi-heat-wave"
          headerText="Warm Prospects"
          valueText="5"
          btnText="View"
          trending="up"
          trendingChange="1.2%"
          rgbaColor="RGBA(241, 157, 56, 0.5)"
      /></v-col>
      <v-col class="col-12 col-md-3">
        <ProspectTemperatureCard
          color="cyan"
          icon="mdi-snowflake-variant"
          headerText="Cold Prospects"
          valueText="24"
          btnText="View"
          trending="down"
          trendingChange="0.2%"
          rgbaColor="RGBA(84, 185, 209, 0.5)"
      /></v-col>
      <v-col class="col-12 col-md-3">
        <ProspectTemperatureCard
          color="primary"
          icon="mdi-star-circle"
          headerText="New Prospects"
          valueText="18"
          btnText="View"
          trending="up"
          trendingChange="1%"
          rgbaColor="RGBA(65, 136, 236, 0.5)"
      /></v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col class="col-md-12 col">
        <v-card outlined>
          <div class="px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0 text-typo">
              Your Risk Score Prospects
            </p>
          </div>
          <v-row>
            <v-col class="col">
              <v-card flat>
                <v-card-text> <ProspectsTable /></v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col class="col-md-5 col">
        <v-card outlined style="height: 100%">
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0 text-typo">
              Prospects Demographics
            </p>
          </div>
          <v-card-text>
            <v-row>
              <v-col class="col">
                <v-list>
                  <v-list-item class="mb-4">
                    <v-row>
                      <v-col class="col-3">
                        <ProspectsPieChart
                          :chartData="{
                            labels: ['Male', 'Female'],
                            datasets: [{ data: [40, 60] }],
                          }"
                        />
                      </v-col>
                      <v-col>
                        <p class="mb-2 font-weight-bold">Gender Distribution</p>
                        <p class="mb-1"><strong>60%</strong> Female</p>
                        <p><strong>40%</strong> Male</p></v-col
                      >
                    </v-row>
                  </v-list-item>
                  <v-list-item class="mb-4">
                    <v-row>
                      <v-col class="col-3">
                        <ProspectsPieChart
                          :chartData="{
                            labels: ['Married', 'Single'],
                            datasets: [{ data: [45, 55] }],
                          }"
                        />
                      </v-col>
                      <v-col>
                        <p class="mb-2 font-weight-bold">
                          Married Distribution
                        </p>
                        <p class="mb-1"><strong>45%</strong> Married</p>
                        <p><strong>55%</strong> Single</p></v-col
                      >
                    </v-row>
                  </v-list-item>
                  <v-divider class="my-4"></v-divider>
                  <v-list-item>
                    <v-card flat class="mx-auto" style="width: 100%">
                      <p class="font-weight-bold mb-6 ml-3 text-center">
                        Geographic Distribution
                      </p>
                      <GeoDistributionMap />
                    </v-card>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-md-7 col">
        <v-card outlined style="height: 100%">
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0">Prospects Activity</p>
          </div>

          <v-row>
            <v-col>
              <v-card flat class="mx-auto pa-4 pt-2">
                <ProspectsLineChart />

                <p class="mt-4 mb-2">
                  <v-icon color="warning" class="mr-2">mdi-alert-circle</v-icon
                  >44 Prospects need attention
                </p>

                <p class="mt-4 mb-2">
                  <v-icon color="info" class="mr-2"
                    >mdi-arrow-right-circle</v-icon
                  >18 Prospects have updated their survey
                </p>
              </v-card></v-col
            >
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HotLeadCarousel from "@/components/intel/HotLeadCarousel";
import ProspectTemperatureCard from "@/components/intel/ProspectTemperatureCard";
import ProspectsTable from "@/components/intel/ProspectsTable";
import GeoDistributionMap from "@/components/intel/GeoDistributionMap";
import ProspectsLineChart from "@/components/intel/ProspectsLineChart";
import ProspectsPieChart from "@/components/intel/ProspectsPieChart";

export default {
  components: {
    HotLeadCarousel,
    ProspectTemperatureCard,
    ProspectsTable,
    GeoDistributionMap,
    ProspectsLineChart,
    ProspectsPieChart,
  },
};
</script>

<style lang="scss" scoped></style>
