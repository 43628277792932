<template>
  <canvas id="canvas"></canvas>
</template>

<script>
import { Chart, CategoryScale, LinearScale, registerables } from "chart.js";
import {
  ChoroplethController,
  GeoFeature,
  ColorScale,
  ProjectionScale,
} from "chartjs-chart-geo";
import * as ChartGeo from "chartjs-chart-geo";

Chart.register(...registerables);
Chart.register(
  CategoryScale,
  ChoroplethController,
  ColorScale,
  GeoFeature,
  LinearScale,
  ProjectionScale
);
export default {
  mounted() {
    this.renderMap();
  },
  methods: {
    renderMap() {
      let canvas = document.getElementById("canvas");
      if (!canvas) return;

      fetch("https://unpkg.com/us-atlas/states-10m.json")
        .then((r) => r.json())
        .then((us) => {
          // console.log("US", us);
          const nation = ChartGeo.topojson.feature(us, us.objects.nation)
            .features[0];
          const states = ChartGeo.topojson.feature(
            us,
            us.objects.states
          ).features;
          // console.log(
          //   " states.map((d) => d.properties.name)",
          //   states.map((d) => d)
          // );
          // const data = [
          //   {
          //     name: "AK",
          //     value: 22,
          //   },
          // ];

          new Chart(document.getElementById("canvas").getContext("2d"), {
            type: "choropleth",
            data: {
              labels: states.map((d) => d.properties.name),
              datasets: [
                {
                  label: "States",
                  outline: nation,
                  // data: [
                  //   {
                  //     feature: "AK",
                  //   }
                  // ]
                  data: states.map((d) => ({
                    feature: d,
                    value: Math.random() * 10,
                  })),
                },
              ],
            },
            options: {
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                xy: {
                  projection: "albersUsa",
                },
                color: {
                  quantize: 5,
                  legend: {
                    // position: "bottom-right",
                    // align: "bottom",
                    display: false,
                  },
                },
              },
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
