<template>
  <g :id="`Male56Avatar_${uniqueIdSuffixA}`">
    <circle fill="#D0D0D0" cx="24.3" cy="47.2" r="23.9" />
    <path
      fill="none"
      stroke="#B5B4B5"
      stroke-width="4"
      stroke-miterlimit="10"
      d="M44.1,37.1c-3.7-7.2-11.2-12.2-19.8-12.2
		C12,24.9,2,34.9,2,47.2s10,22.2,22.2,22.2s22.2-10,22.2-22.2C46.5,43.5,45.6,40.1,44.1,37.1"
    />
    <g>
      <path
        fill="none"
        d="M10.9,60l2.7-0.7l3.9-1.1l1.9-2.9l0.2,0.2v-3.1l0.1-0.1v0c-1.5-1.1-2.4-2.8-2.4-4.9v-0.5h0
			c0,0-0.7-0.3-0.9-0.5c-0.5-0.3-0.8-0.6-0.8-1.3v-1.4c0-0.7,0.1-1.3,0.8-1.3h0.9l0-0.3l0-2.8c0-0.2,0-0.3,0-0.5v0
			c0.3-3.5,3.2-6.2,6.7-6.2h1.1c3.6,0,6.5,2.8,6.7,6.3c0,0.2,0,0.3,0,0.5l0.1,2.7l0,0.2l0.1,0.2h0.8c0.7,0,0.8,0.6,0.8,1.3v1.4
			c0,0.7-0.3,1-0.8,1.3c-0.2,0.1-0.9,0.5-0.9,0.5h-0.1v0.5c0,2-0.9,3.7-2.4,4.8v0c0,0,0,0,0,0l0,0l0.1,0.1v3.1l0.2-0.2l1.9,2.9
			l3.9,1.1l2.7,0.7c0.1,0,0.3,0.1,0.4,0.1c3.1-3.4,5-8,5-13c0-10.7-8.7-19.4-19.4-19.4S4.9,36.5,4.9,47.2c0,5.1,2,9.7,5.2,13.2
			C10.3,60.2,10.6,60.1,10.9,60z"
      />
      <path
        fill="#333333"
        d="M17.5,58.2l-3.9,1.1L10.9,60c-0.3,0.1-0.6,0.2-0.9,0.3c2.6,2.8,5.9,4.8,9.7,5.7l-2.3-7.7L17.5,58.2z"
      />
      <path
        fill="#333333"
        d="M35.6,59.3l-3.9-1.1l0.1,0.1l-0.1,0.2l-2.2,7.4c3.6-1,6.8-3,9.2-5.7c-0.1,0-0.2-0.1-0.4-0.1L35.6,59.3z"
      />
      <path
        fill="#EDC593"
        d="M20.6,49.8c0.1-0.4,1.7-1.5,2.6-2c0.3-0.2,0.7,0,0.8,0.4c0,0.1,0,0.2,0,0.2c-0.3,0.1-2.3,1.5-2.3,1.9
			c0,0.5,1.2,3.2,1.7,3.2c0.4,0,0.5-0.7,0.5-1.1c0-0.2,0.2-0.4,0.3-0.4h0.6c0.2,0,0.3,0.2,0.3,0.4c0,0.4,0.1,1.1,0.5,1.1
			c0.5,0,1.8-2.7,1.7-3.2c0-0.5-2.1-1.9-2.3-1.9c-0.1,0-0.1-0.1,0-0.2c0.1-0.4,0.5-0.5,0.8-0.4c0.9,0.5,2.5,1.6,2.6,2
			c0.2,0.9-0.5,2.6-1,3.7c0.8-0.2,1.5-0.6,2.2-1.1c0,0,0,0,0,0v0c1.4-1.1,2.4-2.8,2.4-4.8v-0.5h0.1c0,0,0.7-0.3,0.9-0.5
			c0.5-0.3,0.8-0.6,0.8-1.3v-1.4c0-0.7-0.1-1.3-0.8-1.3h-0.8L32,42.3l-0.2,2.3L31.6,44c0,0,0.1-2.3-0.4-4.6
			c-0.5-2.1-1.4-4.2-3.3-4.3c-1.1-0.1-1.6,0.3-3,0.3c-1.4,0-2.3-0.3-3.2-0.3c-2,0-2.9,2.2-3.4,4.3c-0.5,2.3-0.4,4.5-0.4,4.5
			l-0.2,0.6l-0.3,2.4v0.5c0,2.1,0.9,3.8,2.4,4.9v0c0.5,0.4,1.2,0.7,1.8,0.9C21,52.2,20.4,50.6,20.6,49.8z M24,45
			c0.1,0,0.5,0,0.7-0.2c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3,0.2-0.5,0.4-0.5c0.3,0,0.5,0.2,0.5,0.4c0,0.7-0.3,1.2-0.5,1.4
			c-0.4,0.4-1,0.4-1.3,0.4c-0.1,0-0.2,0-0.2,0c-0.3,0-0.4-0.3-0.4-0.5C23.5,45.1,23.7,44.9,24,45z"
      />
      <path
        fill="#FFFFFF"
        d="M31.6,44l0.2,0.6l0.2-2.3l0-0.2l-0.1-2.7c0-0.2,0-0.3,0-0.5l-0.7,0.5C31.6,41.6,31.6,44,31.6,44z"
      />
      <path
        fill="#575756"
        d="M18.2,39.4c0.4-2.2,1.4-4.3,3.4-4.3c0.9,0,1.9,0.3,3.2,0.3c1.3,0,1.8-0.4,3-0.3c1.9,0.1,2.9,2.2,3.3,4.3
			l0.7-0.5c-0.2-3.5-3.2-6.3-6.7-6.3H24c-3.5,0-6.5,2.7-6.7,6.2v0L18.2,39.4L18.2,39.4z"
      />
      <path
        fill="#F5F5F5"
        d="M26.6,61.6l1.9,1.7l3.2-4.9l0.1-0.2l-0.1-0.1l-0.4-0.1c-0.9-0.2-1.7-1-1.7-1.9v-0.7L24.8,60L26.6,61.6z"
      />
      <path
        fill="#F5F5F5"
        d="M31.3,58.1l0.4,0.1l-1.9-2.9l-0.2,0.2v0.7C29.6,57.1,30.4,57.9,31.3,58.1z"
      />
      <path
        fill="#ECECEC"
        d="M28.5,63.3l-1.9-1.7l-0.7,1.9h-0.1l0.4,2.9c1.1-0.1,2.2-0.3,3.3-0.6l2.2-7.4L28.5,63.3z"
      />
      <path
        fill="#D3AE84"
        d="M24.8,60l4.8-4.5v-3.1l-0.1-0.1l0,0c-0.6,0.5-1.4,0.9-2.2,1.1c-0.2,0.4-0.4,0.7-0.5,0.9
			c-0.4,0.6-2.4,0.5-2.4,0.5s-2,0.1-2.4-0.5c-0.1-0.2-0.3-0.6-0.6-1c-0.7-0.2-1.3-0.5-1.8-0.9l-0.1,0.1v3.1l4.8,4.5H24.8z"
      />
      <path
        fill="#4080C1"
        d="M23.3,63.6L23,66.5c0.4,0,0.9,0.1,1.3,0.1c0.6,0,1.3,0,1.9-0.1l-0.4-2.9H23.3z"
      />
      <polygon
        fill="#3B79AF"
        points="24.5,60 22.7,61.6 23.3,63.6 25.8,63.6 25.9,63.6 26.6,61.6 24.8,60 		"
      />
      <path
        fill="#D3AE84"
        d="M23.9,45.9c0,0,0.1,0,0.2,0c0.3,0,0.8-0.1,1.3-0.4c0.2-0.2,0.5-0.7,0.5-1.4c0-0.3-0.2-0.5-0.5-0.4
			c-0.3,0-0.5,0.2-0.4,0.5c0,0.3,0,0.5-0.2,0.7C24.5,45,24.1,45,24,45c-0.3,0-0.5,0.2-0.5,0.4C23.4,45.6,23.6,45.9,23.9,45.9z"
      />
      <path
        fill="#F5F5F5"
        d="M20.8,63.3l1.9-1.7l1.8-1.6l-4.8-4.5v0.7c0,0.9-0.9,1.7-1.7,1.9l-0.4,0.1l-0.1,0.1L20.8,63.3z"
      />
      <path
        fill="#ECECEC"
        d="M22.7,61.6l-1.9,1.7l-3.3-5l2.3,7.7c1,0.2,2.1,0.4,3.2,0.5l0.4-2.9L22.7,61.6z"
      />
      <path
        fill="#F5F5F5"
        d="M19.7,56.2v-0.7l-0.2-0.2l-1.9,2.9l0.4-0.1C18.8,57.9,19.7,57.1,19.7,56.2z"
      />
      <path
        fill="#FFFFFF"
        d="M17.8,44c0,0-0.1-2.3,0.4-4.5h0l-0.9-0.6c0,0.2,0,0.3,0,0.5l0,2.8l0,0.3l0.4,2.1L17.8,44z"
      />
      <path
        fill="#EDC593"
        d="M17.2,42.5h-0.9c-0.7,0-0.8,0.6-0.8,1.3v1.4c0,0.7,0.3,1,0.8,1.3c0.2,0.1,0.9,0.5,0.9,0.5h0l0.3-2.4
			L17.2,42.5z"
      />
      <path
        fill="#575756"
        d="M28.4,49.8c-0.1-0.4-1.7-1.5-2.6-2c-0.3-0.2-0.7,0-0.8,0.4c0,0.1,0,0.2,0,0.2c0.3,0.1,2.3,1.5,2.3,1.9
			c0,0.5-1.2,3.2-1.7,3.2c-0.4,0-0.5-0.7-0.5-1.1c0-0.2-0.2-0.4-0.3-0.4h-0.6c-0.2,0-0.3,0.2-0.3,0.4c0,0.4-0.1,1.1-0.5,1.1
			c-0.5,0-1.8-2.7-1.7-3.2c0-0.5,2.1-1.9,2.3-1.9c0.1,0,0.1-0.1,0-0.2c-0.1-0.4-0.5-0.5-0.8-0.4c-0.9,0.5-2.5,1.6-2.6,2
			c-0.2,0.9,0.4,2.4,1,3.5c0.3,0.1,0.7,0.2,1.1,0.3h4.3c0.2,0,0.3-0.1,0.5-0.1C27.9,52.3,28.6,50.7,28.4,49.8z"
      />
      <path
        fill="#575756"
        d="M22.6,53.5c-0.4-0.1-0.7-0.2-1.1-0.3c0.2,0.5,0.5,0.9,0.6,1c0.4,0.6,2.4,0.5,2.4,0.5s2,0.1,2.4-0.5
			c0.1-0.2,0.3-0.5,0.5-0.9c-0.2,0-0.3,0.1-0.5,0.1H22.6z"
      />
    </g>
  </g>
</template>
<script>
export default {
  computed: {
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
  },
};
</script>
