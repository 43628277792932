<template>
  <div class="pa-8">
    <p class="font-weight-bold mb-4 text-typo">New Hot Prospects</p>
    <v-card elevation="12" outlined>
      <v-carousel
        cycle
        hide-delimiters
        :interval="3000"
        show-arrows-on-hover
        height="350"
      >
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-card flat class="d-flex flex-column" style="height: 100%">
            <v-icon
              color="rgba(225, 82, 65, 1)"
              style="position: absolute; top: 5px; left: 5px"
              >mdi-fire-circle</v-icon
            >
            <v-card-text class="flex-grow-1 d-flex flex-column">
              <div
                style="
                  width: 72px;
                  margin: 0 auto;
                  border-radius: 50%;
                  border: 3px solid red;
                  box-shadow: 0 0 20px RGBA(225, 82, 65, 0.5);
                  overflow: hidden;
                "
                class="mb-3"
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0.3 23.3 47.8 48"
                  xml:space="preserve"
                  style="vertical-align: bottom"
                >
                  <template v-if="item.gender === 'female'">
                    <Female55Avatar />
                  </template>
                  <template v-if="item.gender === 'male'">
                    <Male56Avatar />
                  </template>
                </svg>
              </div>
              <div class="mb-2 text-h6 text-center">
                {{ item.firstName }} {{ item.lastName }}
              </div>

              <v-row class="text-center">
                <v-col class="pa-0"> {{ item.email }}</v-col>
              </v-row>

              <v-row class="text-center">
                <v-col> Gender: {{ item.gender }}</v-col>
                <v-col> Age: {{ item.age }}</v-col>
              </v-row>

              <v-row class="text-center">
                <v-col>
                  <p class="mb-1 font-weight-bold text-h3">{{ item.score }}</p>
                  <p class="mb-0">Risk Score</p>
                </v-col>

                <v-col>
                  <p class="mb-1 font-weight-bold text-h3">
                    {{ item.likelihood }}
                  </p>
                  <p class="mb-0">Likelihood</p>
                </v-col>

                <v-col class="col-12 pa-0">
                  <small>Date Submitted: {{ getDate }}</small>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn text color="primary">
                View Profile <v-icon small>mdi-arrow-right</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import Female55Avatar from "@/components/reportAnimationAvatars/Female55Avatar";
import Male56Avatar from "@/components/reportAnimationAvatars/Male56Avatar";

export default {
  components: {
    Female55Avatar,
    Male56Avatar,
  },
  data() {
    return {
      items: [
        {
          gender: "female",
          age: "56",
          firstName: "Jennifer",
          lastName: "Bloomfield",
          email: "jbloom@gmail.com",
          score: 74,
          likelihood: "41%",
        },
        {
          gender: "male",
          age: "64",
          firstName: "Tim",
          lastName: "Johnson",
          email: "jbloom@gmail.com",
          score: 86,
          likelihood: "67%",
        },
        {
          gender: "male",
          age: "44",
          firstName: "Art",
          lastName: "Vandalay",
          email: "latexVP@vanadalayind.com",
          score: 88,
          likelihood: "34%",
        },
      ],
    };
  },
  computed: {
    getDate() {
      const date = new Date();

      return date.toDateString(); //`${date.getMonth()} ${date.getDate()}, ${date.getFullYear()}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
