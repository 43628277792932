<template>
  <Pie :chart-options="chartOptions" :chart-data="chartData" />
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import { Chart, CategoryScale, LinearScale, registerables } from "chart.js";

Chart.register(...registerables);
Chart.register(CategoryScale, LinearScale);

export default {
  props: {
    chartData: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Pie,
  },
  data() {
    return {
      // chartData: {
      //   labels: ["Male", "Female"],
      //   datasets: [{ data: [40, 60] }],
      // },
      chartOptions: {
        responsive: true,

        plugins: {
          legend: {
            display: false,
          },
        },
        backgroundColor: ["#4188EC", "#6EA4F1"],
        chartData() {
          this.renderChart(this.chartData, this.chartOptions);
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
